

import React from 'react'
import SeoPage from '../components/SeoPage'

import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/engager-meilleurs-avocats-ville-levis-conseils-services-juridiques-768x457.jpeg'



const Levis = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Dénichez les meilleurs avocats à Lévis pour tous vos services juridiques! - Soumissions Avocat"
        description="La profession légale en est une parsemée de formalités, de mystère et de nombreuses légendes urbaines. Rien ne sert de cacher que les histoires sur les avocats et les chicanes de clôture sont pour le moins courantes dans l’imaginaire collectif. Toutefois, en dépit de l’opinion qui peut être propagée."
        image={LeadImage}>
        <h1>Dénichez les meilleurs avocats à Lévis pour tous vos services juridiques!</h1>
        <p>
            La profession légale en est une parsemée de formalités, de mystère et de
            nombreuses légendes urbaines. Rien ne sert de cacher que les histoires sur
            les avocats et les chicanes de clôture sont pour le moins courantes dans
            l’imaginaire collectif. Toutefois, en dépit de l’opinion qui peut être
            propagée sur cette profession, lorsque le besoin d’une représentation
            légale survient, il vaut mieux s’en tenir aux faits pour bénéficier des
            meilleurs conseils possibles.
        </p>
        <p>
            <StaticImage
                src="../images/engager-meilleurs-avocats-ville-levis-conseils-services-juridiques-768x457.jpeg"
                alt="engager-meilleurs-avocats-ville-levis-conseils-services-juridiques"
            />
        </p>
        <p>
            De ce fait, quel est le véritable rôle de l’avocat? Quelles sont ses
            responsabilités à votre égard? Qu’est-ce qui distingue le procès civil du
            criminel? De valides questions, c’est le moins qu’on puisse dire! L’avocat
            se veut un représentant et un allié indispensable dans l’engrenage du
            système juridique. Nous vous présentons son rôle plus en détail pour que
            vous dénichiez les meilleurs avocats à Lévis!
        </p>
        <h2>
            Quels sont les avantages d’aller voir un avocat à Lévis?
        </h2>
        <p>
            Un avocat est un professionnel du droit mettant ses services de
            représentation au service de la communauté. Il joue un rôle de mandataire,
            de conseiller et d’allié juridique. Il sait épauler les clients à se sortir
            de tous les types de problèmes légaux; c’est ce qui fait de lui un
            professionnel aussi indispensable. Mais concrètement, qu’est-ce qui rend la
            visite chez l’avocat si profitable?
        </p>
        <p>
            <strong>Conseils éclairés. </strong>
            Forts d’une formation universitaire et de la réussite de l’école du Barreau
            du Québec, les avocats ont une compétence unique pour prodiguer des
            conseils en matière de droit. Non seulement sont-ils aptes dans presque
            tous les domaines imaginables, mais leur expertise augmente grandement vos
            chances de remporter votre cause.
        </p>
        <p>
            <strong>Problème réglé rapidement. </strong>
            Que ce soit par l’entremise d’un règlement hors cour ou d’une résolution de
            différend privée, les avocats en connaissent suffisamment sur la sphère
            juridique pour savoir comment accélérer la résolution des problèmes qui
            leur sont soumis. Bien qu’ils ne contrôlent pas les délais et caprices du
            système juridique, ils sauront vous conseiller sur la route à emprunter!
        </p>
        <p>
            <strong>
                <br />
                Expertise unique.
            </strong>
            Aucun autre expert ne saurait vous conseiller comme un avocat sait le
            faire! En effet, même les notaires, qui sont pourtant des conseillers
            juridiques de premier plan, ne sauraient intervenir pour les mêmes disputes
            que les avocats.
        </p>
        <p>
            <strong>
                <br />
                C’est donc réellement un service juridique de premier plan que vous
                obtenez en allant consulter un avocat à Lévis!
            </strong>
        </p>
        <h2>
            Informez-vous sur les différentes expertises légales et l’aide juridique!
        </h2>
        <p>
            Outre les avantages de solliciter un avocat, il est important de savoir à
            quoi ressemble leur champ d’expertise et l’étendue de leurs services. La
            polyvalence des avocats est certainement ce qui fait d’eux des
            professionnels aussi compétents et recherchés. Notamment, ils œuvrent dans
            les sphères juridiques suivantes :
        </p>
        <p>
            <StaticImage
                placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/services-avocats-redaction-contrats-representation-litige-levis.jpeg"
                alt="services-avocats-redaction-contrats-representation-litige-levis"
            />
        </p>
        <ul>
            <li>
                <strong>La rédaction de contrats : </strong>
                Bien que les contrats entre particuliers ne demandent que très peu de
                formalités, engager un avocat pour mettre l’entente en évidence
                garantit les chances que celle-ci soit respectée. La rédaction
                contractuelle est d’ailleurs une compétence que les avocats apprennent
                dès les premiers moments de leur formation.
            </li>
            <li>
                <strong>La représentation en cas de litige : </strong>
                Nul n’est tenu d’être représenté en cour! Si vous êtes du type
                indépendant et autodidacte, il est tout à fait possible de vous
                représenter vous-mêmes en cour et aucun juge ne s’y opposera! Ceci dit,
                ce n’est certes pas la meilleure façon de maximiser vos chances de
                gagner n’est-ce pas? La plaidoirie est une spécialité au sein du droit.
                Engager un avocat plaideur est donc un incontournable!
            </li>
            <li>
                <strong>Le règlement de différend : </strong>
                Aussi appels les « modes privés » de règlement de différend,
                l’arbitrage, la médiation et la conciliation consistent à utiliser tous
                les moyens possibles pour régler l’entente hors cours. Les avocats
                interviennent généralement pour assister les partis à trouver un
                terrain d’entente ou encore pour agir comme arbitre décideur.
            </li>
            <li>
                <strong>La planification fiscale :</strong>
                La fiscalité est un mastodonte du droit! Les dispositions législatives
                sont complexes et exhaustives. C’est pourquoi les avocats se
                spécialisant en droit fiscal opèrent rarement dans d’autres domaines de
                droit. De ce fait, pour tous vos soucis au niveau fiscal, tel que la
                réception d’un défaut de paiement d’impôt, d’une sanction du Ministère
                du Revenu ou tout autre problème, un avocat fiscaliste saura vous
                montrer la marche à suivre.
            </li>
            <li>
                <strong>Le droit successoral : </strong>
                On a peut-être tendance à croire que le domaine de la succession est
                mieux laissé entre les mains des notaires, mais les avocats y ont leur
                place également! En effet, autant en termes de planification
                successorale que pour la rédaction d’un testament devant témoins, les
                avocats sont compétents pour vous aider!
            </li>
        </ul>
        <p>
            <strong>
                Quelles sont les conditions d’admissibilité à l’aide juridique?
            </strong>
            Le système d’aide juridique fut mis en place pour aider les gens plus
            démunis à jouir du droit fondamental de la représentation légale. Ainsi,
            des barèmes sont prévus pour déterminer le montant d’aide juridique
            admissible.
        </p>
        <p>
            Des tranches de revenus permettent donc d’obtenir un montant déterminé et,
            lorsque ceux-ci sont trop faibles, l’assistance légale est complètement
            gratuite. Il est donc avantageux de s’informer d’un tel service. Également,
            un avocat qui constate une situation financière précaire chez un de ses
            clients est tenu de faire part de l’existence d’un tel programme à ce
            dernier.
        </p>
        <h2>
            L’avocat, expert en matière contractuelle
        </h2>
        <p>
            Le droit civil est constitué en grande partie du régime contractuel qui
            régit les ententes entre les particuliers. Ce régime comporte des règles
            d’équité et de fonctionnement très précises. Cette précision est également
            accompagnée d’une certaine complexité qui justifie l’intervention d’un
            avocat pour la rédaction de votre contrat!
        </p>
        <h3>
            Les grands principes contractuels
        </h3>
        <p>
            Encore trop de gens sont faussement portés à croire qu’un contrat, pour
            être valide, doit être écrit sur un morceau de papier. C’est toutefois loin
            d’être le cas! Le Code civil du Québec ne fait aucune mention d’une telle
            exigence. La loi ne mentionne qu’un simple échange de consentement entre
            deux personnes capables de contracter.
        </p>
        <p>
            Cela implique que lorsque vous faites une offre verbale à une personne et
            que ce dernier l’accepte sans équivoque, vous êtes en présence d’un contrat
            verbal constitué en bonne et due forme. Le problème? Le prouver lors de
            l’inexécution, évidemment!
        </p>
        <h3>
            Les éléments de base d’un contrat
        </h3>
        <ul>
            <li>
                <strong><em>La capacité :</em></strong>
                Pour qu’un contrat soit valide, les deux personnes concluant une
                entente doivent avoir la capacité juridique de le faire. Ils doivent
                donc avoir la capacité en fonction de leur âge, mais également de leur
                état mental.
            </li>
            <li>
                <strong><em>L’offre</em></strong>
                <em> :</em>
                Pour qu’une offre soit considérée comme telle au sens de la loi, elle
                doit démontrer une volonté ferme et précise de vouloir contracter. Elle
                doit également contenir tous les éléments essentiels du contrat
                envisagé, sans quoi elle ne se qualifiera pas comme une véritable
                offre.
            </li>
            <li>
                <strong><em>L’acceptation</em></strong>
                <em> :</em>
                En revanche, une acceptation en sera considérée comme une lorsque le
                récipiendaire de l’offre manifestera une volonté claire de contracter
                selon les termes de l’offre reçue. Du moment qu’une ambiguïté se
                manifeste au niveau de la volonté, il sera impossible de considérer
                l’acceptation comme validement formulée.
            </li>
        </ul>
        <p>
            <strong>
                L’annulation, la résolution et la résiliation d’un contrat avec un
                avocat!
            </strong>
            Vous trouvez que la formation d’un contrat est complexe? Imaginez ce qui en
            est de l’inexécution contractuelle! En effet, lorsqu’un parti refuse de
            respecter le contrat conclu ou qu’un vice s’est glissé dans la formation de
            l’entente, il sera possible de la faire annuler. N’allez pas penser que
            cela sera du jour au lendemain. Les services d’un avocat seront
            indispensables soyez en sûr!
        </p>
        <p>
            <strong> </strong>
        </p>
        <h2>
            Le contrat de consommation et les protections offertes par la loi
        </h2>
        <p>
            Le principe de la liberté contractuelle et du respect de la parole donnée
            veut que les parties à un contrat soient tenues de respecter leurs
            engagements, que ceux-ci se révèlent ultérieurement désavantageux ou non.
            Ce principe connaît cependant plusieurs nuances dans le contrat de
            consommation en raison du rapport de force qui existe entre les fabricants
            de produits de consommation et les consommateurs.
        </p>
        <p>
            <strong>
                Quelles sont les particularités du contrat de consommation?
            </strong>
            Tout d’abord, ils doivent absolument être constatés par écrit. En effet,
            alors que le contrat traditionnel entre particuliers peut être valablement
            formé de façon verbale, celui entre un commerçant et un consommateur ne
            peut se passer d’un écrit. D’ailleurs, il faut absolument que le commerçant
            remette une copie au moment de former le contrat.
        </p>
        <p>
            Au niveau des termes du contrat lui-même, la loi prévoit des exigences
            additionnelles au niveau de la clarté des termes et des clauses qui y
            apparaissent. Alors qu’une clause ambiguë dans contrat ordinaire doit être
            soumise à l’interprétation du tribunal selon les règles prévues au Code
            civil du Québec, le contrat de consommation qui comprend une clause
            illisible ou incompréhensible sera invalide.
            <br />
            <strong>
                <br />
                Quels contrats se qualifient comme étant « de consommation »?
            </strong>
            Pour ne nommer que quelques exemples, les contrats d’achat en ligne, les
            abonnements à des salles d’entraînement se les contrats de location
            d’automobiles se qualifient tous comme des contrats de location et sont
            donc soumis au régime contractuel spécial de la    <em>Loi sur la protection du consommateur</em>.
        </p>
        <h2>
            Qu’est-ce qui est couvert par la Loi sur la protection du consommateur
        </h2>
        <p>
            Pour que les protections mentionnées précédemment prennent effet au moment
            de vous prévaloir des avantages de la Loi sur la protection du
            consommateur, toujours faut-il que vous ayez bel et bien conclu un contrat
            de consommation.
            <strong>
                Des critères bien précis sont définis pour qu’on considère un contrat
                comme tel, et les voici!
            </strong>
        </p>
        <p>
            <StaticImage
                placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/protection-consommateur-avocat-levis-768x512.jpg"
                alt="protection consommateur avocat levis"
            />
        </p>
        <p>
            Tout d’abord, vous devez être une personne physique ayant contracté en son
            nom personnel. Une personne physique est tout individu n’étant pas une
            personne morale, c’est-à-dire, une entreprise incorporée. Aux fins de
            l’application de la Loi sur la protection du consommateur, il faut que
            cette personne contracte pour son propre compte pour bénéficier des
            protections légales et non au bénéfice d’une entreprise.
        </p>
        <p>
            De plus, ce même contrat doit avoir été conclu avec un commerçant. Comment
            se définit une telle personne au sens de la loi? Il s’agit de toute
            personne exerçant une activité économique organisée prenant part au
            commerce de biens. La loi ne précise pas de taille minimale pour le
            commerçant; il faut seulement qu’il exerce des activités commerciales.
        </p>
        <p>
            Il faut également prendre note que les contrats conclus avec des
            professionnels comme les notaires, comptables et avocats de ce monde ne
            seront pas considérés comme des contrats de consommation, car ceux-ci ne
            sont pas réputés exercer des activités commerciales au sens de la loi.
        </p>
        <p>
            <strong>
                Que faire lorsque vous avez conclu un contrat abusif ou invalide?
            </strong>
            Consultez un avocat dans le but d’intenter un recours à la Cour des petites
            créances ou à la Cour du Québec selon la valeur du bien de consommation! Le
            fait que le commerçant soit une multinationale ne le soustrait pas à
            l’application de la loi et vous pouvez toujours faire valoir vos droits à
            son égard.
        </p>
        <h2>
            Les garanties légales et conventionnelles du consommateur expliquées!
        </h2>
        <p>
            Avez-vous déjà acheté un appareil ou un autre bien de consommation qui a
            finalement rendu l’âme après seulement quelques semaines d’utilisation?
            Frustrant, n’est-ce pas! C’est justement pour éviter de tels scénarios que
            la Loi sur la protection du consommateur a mis en place le régime des
            garanties afin d’assurer aux consommateurs une certaine durée de vie aux
            biens qu’ils se procurent. La garantie légale et la garantie
            conventionnelle sont les deux principales protections qu’on vous explique
            ci-bas!
        </p>
        <p>
            <strong>Ce que couvre la garantie légale : </strong>
            La garantie légale est la protection de base que tout commerçant doit
            offrir. En fait, elle accompagne tout bien vendu même si le commerçant ne
            l’a pas prévue explicitement et elle est liée directement au bien, ce qui
            signifie que même les acheteurs de seconde main peuvent s’en prévaloir si
            elle n’est pas expirée.
        </p>
        <p>
            La garantie légale en est une qui se scinde en plusieurs sphères. Elle
            couvre avant tout la qualité du bien acheté afin que l’acheteur puisse au
            moins en faire l’usage « normal ». Un terme aussi large doit être adapté
            selon chaque type de produit. En somme, si vous avez acheté un grille-pain,
            vous devez être en mesure de griller du pain. Si ce n’est pas le cas, la
            garantie légale trouve application.
        </p>
        <p>
            Cette garantie prévoit également que le bien doive avoir une durée de vie
            raisonnable. Chaque produit disposante d’une durée de vie différente, la
            raisonnabilité de sa durée de vie dépendra de sa nature. Votre nouvelle
            voiture durera plus longtemps que votre grille-pain, n’en soyez pas
            surpris.
        </p>
        <p>
            Le bien acheté doit également, en vertu de la loi, être conforme à la
            description promise non seulement dans le contrat conclu, mais également à
            la publicité du produit ainsi qu’aux représentations faites par le vendeur.
            Cette protection fait partie intégrante de la garantie légale, au même
            titre que la sécurité du bien, en ce sens qu’un bien ne peut légalement
            mettre en péril la sécurité de l’utilisateur, sous réserve des biens qui
            sont intrinsèquement dangereux.
        </p>
        <p>
            Finalement, la garantie légale de qualité protège le consommateur contre
            les vices caché. Celui-ci doit être important, grave et non apparent. Le
            défaut sera grave si, au moment de la vente, on vous l’avait divulgué, vous
            n’auriez pas acheté le bien. Il sera grave si le bien ne peut pas
            fonctionner pour accomplir la fonction qu’il est censé assumer.
        </p>
        <p>
            <strong>Ce que couvre la garantie conventionnelle : </strong>
            Tel que mentionné, la garantie légale est le seuil minimal obligatoire;
            elle sera toujours disponible et le commerçant ne peut pas s’en défaire. En
            revanche, les garanties conventionnelles sont des protections
            additionnelles que le commerçant offre à sa clientèle afin de garantir la
            qualité de son produit pour une durée parfois plus longue que la garantie
            légale ou encore pour élargir la couverture en termes de qualité.
        </p>
        <p>
            La garantie conventionnelle peut provenir soit du fabricant, du commerçant
            qui vend le produit ou encore des deux! La garantie conventionnelle peut
            contenir toute clause contractuelle de garantie que le fabricant ou
            commerçant souhaite, mais sachez que si celle-ci est moins avantageuse que
            la garantie légale, cette dernière s’appliquera et votre garantie
            conventionnelle ne s’appliquera que dans la mesure où elle vous est plus
            profitable.
        </p>
        <p>
            Au même titre que la garantie légale, celle conventionnelle peut également
            être rattachée directement au bien acheté. Ainsi, l’acheteur du bien usagé
            pourra également se prévaloir de la garantie conventionnelle, du moment
            qu’elle est toujours en vigueur.
        </p>
        <p>
            Lorsqu’un bien de grande valeur et récemment acheté vous fait défaut, il
            est important de vous prévaloir des protections offertes par la    <em>Loi sur la protection du consommateur</em>, et ce, rapidement! Pour
            faire valoir vos droits, vous avez besoin d’un avocat qui s’y connait dans
            le domaine et qui pourra vous représenter face aux commerçants.
        </p>
        <h2>
            Accusé au criminel? Voici les conseils à suivre pour augmenter vos chances
            d’être acquittés!
        </h2>
        <p>
            Bien que les enjeux de droit civil aient une certaine importance, ces
            problèmes semblent toujours moindres lorsque comparés aux accusations
            criminelles. En effet, lorsqu’un dossier criminel ou une peine
            d’emprisonnement menace de vous tomber dessus, c’est qu’on parle d’une
            accusation sérieuse. Cela nécessite inévitablement l’intervention d’un
            avocat en droit criminel. Voyez par vous-mêmes comment se passent les
            différentes étapes d’une accusation criminelle.
        </p>
        <p>
            <StaticImage
                placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                src="../images/avocat-droit-criminel-accusations-defense-etapes-proces-conseils.jpeg"
                alt="avocat-droit-criminel-accusations-defense-etapes-proces-conseils"
            />
        </p>
        <h3>
            <br />
            Les étapes d’une accusation criminelle
        </h3>
        <ul>
            <li>
                <strong><em>Arrestation</em></strong>
                <em> :</em>
                La première étape du processus d’accusation criminelle se veut
                l’arrestation par un policier. Celui-ci amène la personne au poste et
                on lui remet une citation à comparaître lui indiquant le jour où il
                doit se présenter en cour. Toutefois, si on juge que l’accusé
                représente un danger, il sera gardé en détention jusqu’à son procès ou
                jusqu’au moment où un juge ordonne la libération.
            </li>
            <li>
                <strong><em>Comparution</em></strong>
                <em> : </em>
                L’étape qui se passe rapidement après l’arrestation, la comparution
                consiste à lire à l’accusé les charges criminelles retenues contre lui.
                Ce dernier, lors de la même occasion, inscrira son plaidoyer de
                culpabilité ou de non-culpabilité. Les preuves accumulées contre
                l’accusé lui sont également transmises afin qu’il puisse se défendre
                convenablement.
            </li>
            <li>
                <strong><em>Audience remise en liberté provisoire :</em></strong>
                Lorsqu’une personne accusée est jugée dangereuse pour la société, elle
                sera gardée en détention pour la durée des procédures. Celle-ci peut
                toutefois s’opposer à une pareille mesure et exiger la tenue d’une
                audience de remise en liberté provisoire lors de laquelle il tentera de
                convaincre le juge qu’il ne représente pas le danger allégué et qu’il a
                le droit d’être libéré en attendant l’issu des procédures.
            </li>
            <li>
                <strong><em>Enquête préliminaire : </em></strong>
                L’enquête préliminaire ne sert pas à déterminer la culpabilité de
                l’accusé, mais plutôt à vérifier si la couronne détient des preuves
                suffisantes pour qu’un procès soit tenu. Le juge analysera donc la
                preuve accumulée et décidera si elle est suffisante. En cas contraire,
                les charges seront abandonnées.
            </li>
            <li>
                <strong><em>Procès et verdict :</em></strong>
                Le moment où le procureur et la défense présentent leurs arguments
                devant un juge ou un juge et jury. Lorsqu’un juge siège seul, il est le
                maître des procédures et de la décision : il rend le verdict de
                culpabilité ainsi que la peine infligée. Lorsque le juge est accompagné
                d’un jury, c’est le jury qui décidera de la culpabilité, alors que le
                juge rendra le verdict sur la sentence.
            </li>
            <li>
                <strong><em>Représentation sur la peine (Si nécessaire) :</em></strong>
                Lorsqu’une personne est trouvée coupable, il y aura lieu de tenir une
                représentation sur la peine. Les deux partis présenteront leurs
                recommandations sur la durée d’emprisonnement recommandée ou sur les
                alternatives possibles (probation, peine purgée en discontinue, etc.).
            </li>
            <li>
                <strong><em>Appel (si nécessaire) :</em></strong>
                En cas de désaccord, une personne peut présenter une demande d’appel de
                la décision. Il est important de stipuler que l’appel n’est pas un
                second procès, mais bien une révision de l’application du droit aux
                faits. Le juge de première instance demeure donc le maître des faits et
                la cour d’appel se contentera de déterminer si une erreur de droit
                s’est glissée dans le jugement.
            </li>
        </ul>
        <p>
            <strong>
                On ne souhaite à personne de se retrouver dans une situation
                d’accusation criminelle, mais si tel est votre cas, ne négligez surtout
                pas d’engager un avocat! Laissez Soumissions Avocat vous référer aux
                meilleurs avocats en droit criminel à Lévis!
            </strong>
        </p>
        <h2>
            Comment se déroule une première rencontre avec un avocat?
        </h2>
        <p>
            Comme très peu de gens ont eu affaire à un avocat dans leur vie, le
            fonctionnement d’un tel processus est peut-être bien étranger pour
            plusieurs. Le déroulement de la rencontre initiale, du suivi effectué et de
            l’implication nécessaire du client sont tous des éléments auxquels il est
            important d’avoir réponse. Voici à quoi vous attendre pour votre première
            rencontre et les divers éléments à préparer.
        </p>
        <p>
            <strong>
                <StaticImage
                    placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}
                    src="../images/questions-avocat-deroulement-premiere-rencontre-conseils-levis.jpeg"
                    alt="questions-avocat-deroulement-premiere-rencontre-conseils-levis"
                />
            </strong>
        </p>
        <ul>
            <li>
                <strong>Vous posez les questions que vous jugez importantes</strong>
            </li>
        </ul>
        <p>
            Il n’y a pas de mauvaises questions entourant votre situation juridique.
            Que vous soyez aux prises avec une cause civile ou criminelle, les
            conséquences d’un revers en cour sont toujours importantes. C’est pourquoi
            vous devez vous sentir à l’aise de poser toutes les questions que vous
            jugez importantes. Cela vous mettra en confiance et vous en apprendrez
            davantage sur le processus par le fait même.
        </p>
        <ul>
            <li>
                <strong>
                    Vous vous remémorez les faits et tentez de les raconter de façon
                    cohérente.
                </strong>
            </li>
        </ul>
        <p>
            La profession légale consiste principalement à appliquer le droit à une
            situation factuelle. Par exemple, qui est responsable pour la chute dans
            les escaliers glacés de votre immeuble en copropriété? Il vous faudra donc
            vous rappeler le plus en détail possible la trame factuelle afin que votre
            avocat présente des arguments de droit qui arborent dans le même sens. Plus
            vous vous souvenez des détails, mieux c’est!
        </p>
        <ul>
            <li>
                <strong>
                    Vous amenez les documents pertinents avec vous chez votre avocat.
                </strong>
            </li>
        </ul>
        <p>
            Tous les documents pouvant possiblement faire office de preuve sont
            pertinents à amener chez votre avocat. Si vous êtes aux prises avec une
            dispute de droit immobilier concernant votre propriété, amenez les
            certificats de localisation, les plans cadastraux, la chaîne de titres et
            tous les autres documents que vous détenez sur votre propriété. Ils
            pourraient bien être épiés par le tribunal lors d’un procès ou, mieux
            encore, être assez persuasifs pour convaincre l’autre partie d’abandonner
            sa poursuite.
        </p>
        <ul>
            <li>
                <strong>
                    Vous laissez l’avocat vous dicter les procédures à suivre.
                </strong>
            </li>
        </ul>
        <p>
            Prendre des initiatives a son lot d’avantages, mais il vient aussi un temps
            pour laisser l’avocat parler! Une fois en contrôle de votre situation,
            celui-ci vous dictera la suite des procédures à entamer pour régler votre
            dossier. Il vous dira également s’il vaut la peine de continuer une telle
            poursuite ou s’il existe des façons d’éviter de faire durer la bataille
            inutilement.
        </p>
        <h2>
            Questions fréquentes sur les avocats en droit québécois
        </h2>
        <p>
            Tel que mentionné, poser des questions lors de la première rencontre avec
            un avocat est essentiel. Toutes vos inquiétudes devraient faire l’objet
            d’un questionnement à l’endroit de ce dernier. Cependant, il se peut que
            vous vouliez poser des questions auxquelles vous n’aviez même pas pensé!
            C’est pourquoi nous vous avons préparé une courte liste!
        </p>
        <h3>
            Quelles sont ses méthodes de travail?
        </h3>
        <p>
            Vous contactera-t-il régulièrement pour vous faire un suivi du dossier?
            Vous enverra-t-il des courriels de mise à jour? Comment collaborez-vous au
            dossier de façon significative? En discutant avec votre avocat de ses
            méthodes de travail, vous saurez à quoi vous en tenir en termes de la
            contribution qu’il s’attend de votre part et des efforts personnels que
            vous aurez à mettre au dossier.
        </p>
        <h3>
            À quoi ressemblent vos alternatives de règlement de différend?
        </h3>
        <p>
            Aller en cour est un processus fastidieux. C’est donc véritablement une
            option de dernier recours que les avocats utilisent lorsque les partis ne
            réussissent pas à s’entendre à l’amiable. À ce titre, vous devriez demander
            d’entrée de jeu à votre avocat quelles sont vos options de règlement à
            l’amiable. Cela pourrait vous faire sauver du temps et de l’argent!
        </p>
        <h3>
            Est-il habitué de traiter de dossiers similaires au vôtre?
        </h3>
        <p>
            L’expérience qu’un avocat détient dans le règlement de dossiers similaires
            au vôtre influence non seulement vos chances de succès, mais également le
            temps requis pour régler le dossier. Comme son expérience influence le prix
            payé et possiblement le verdict, il est important de lui demander un
            historique professionnel sur les dossiers qu’il a pris en charge au cours
            de sa carrière.
        </p>
        <h3>
            Quelles sont ses impressions sur vos chances de gagner?
        </h3>
        <p>
            On ne sait jamais de quel côté un juge tranchera en cour, mais avec de
            l’expérience, les avocats sont généralement en mesure de déterminer vos
            chances de gagner dès les premiers instants de l’analyse de votre dossier.
            Évidemment, plus votre dossier se retrouve dans une zone grise du droit,
            plus il sera difficile de déterminer vos chances, mais posez tout de même
            la question!
        </p>
        <h3>
            Quel type d’honoraires charge-t-il et à combien estime-t-il le montant
            total?
        </h3>
        <p>
            Les avocats ont le choix de charger un taux horaire, à pourcentage, en
            provision ou encore un montant forfaitaire. Vous devez absolument en
            discuter avec votre avocat, car peu importe son fonctionnement,
            souvenez-vous qu’une expertise légale n’est jamais à bas prix! exigez
            également une estimation du coût des services!
        </p>
        <h3>
            Qu’est-ce que vous pouvez faire pour aider?
        </h3>
        <p>
            Même si vous payez pour qu’un avocat règle votre dossier, lui fournir un
            coup de main accéléra le règlement du dossier. Même si vous ne connaissez
            rien au droit, arriver préparé aux rencontres et remettre les documents
            demandés dans les meilleurs délais expédiera le processus, cela est
            certain!
        </p>
        <h2>
            Les qualités d’un bon avocat : apprenez à les reconnaître pour engager
            celui qui vous convient!
        </h2>
        <p>
            Des avocats, il y en a de toutes sortes! C’est pourquoi plusieurs qualités
            peuvent faire un bon avocat. Cependant, nous avons constaté certaines
            constantes dans le milieu du droit que vous devriez tenter de retrouver
            chez votre juriste à en devenir.
        </p>
        <ul>
            <li>
                <strong>Le professionnalisme :</strong>
                Le droit est un milieu très sérieux et formel. Votre expert devrait
                donc démontrer un certain sens du professionnalisme. Cela vous mettra
                en confiance et portraira une image plus adéquate en cour.
            </li>
            <li>
                <strong>La rigueur :</strong>
                Le mot « complexe » est un doux euphémisme utilisé pour décrire la
                pratique du droit. De ce fait, un bon avocat ne peut se permettre de
                tourner les coins ronds, son travail de recherche doit être approfondi
                si vous voulez mettre toutes les chances de votre côté de remporter
                votre cause.
            </li>
            <li>
                <strong>L’esprit critique :</strong>
                Un procès est un débat devant une tierce personne détenant le pouvoir
                décisionnel. Votre avocat doit donc avoir un esprit vif et critique qui
                lui permettra de convaincre un juge que son interprétation du droit est
                bel et bien la bonne!
            </li>
            <li>
                <strong>La communication</strong>
                : La communication entre un avocat et son client est la clé d’une
                relation professionnelle prospère. Si votre professionnel du droit est
                incapable de vous tenir informé des développements de votre dossier,
                vous seriez peut-être mieux servis entre les mains d’un autre!
            </li>
        </ul>
        <h2>
            Soumissions Avocat s’occupe de dénicher les meilleurs avocats à Lévis juste
            pour vous!
        </h2>
        <p>
            À Lévis, les avocats ne demandent pas mieux que de vous aider à régler vos
            disputes légales et de répondre à tous vos besoins en matière de
            représentation. Ils sauront régler votre litige dans les plus brefs délais,
            et ce, à un prix dans les plus bas standards du marché!
        </p>
        <p align="center">
            <strong>
                Ainsi, tout ce qu’il vous reste à faire, c’est de remplir notre
                formulaire afin de recevoir 3 soumissions gratuites d’avocats
                travaillant dans la ville de Lévis!
            </strong>
        </p>
        <p align="center">
            <strong>
                N’hésitez pas à nous contacter, nos services sont gratuits en plus
                d’être sans engagement!
            </strong>
        </p>

    </SeoPage>
)
export default Levis
